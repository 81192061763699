import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import doc from "../components/03.09.2024.pdf";
import doc2 from "../components/собрание.pdf";
import doc3 from "../components/Особый противопожарный режим8032025.pdf";
import doc4 from "../components/Приказ8032025.pdf";

function Main() {
  const [isOpen, setIsOpen] = useState(true);
  function handleClick() {
    if (isOpen === false) {
      setIsOpen(true);
    } else setIsOpen(false);
  }
  return (
    <div className="main_section">
      <section className="intro" id="about">
        <div className="mark">
          <HashLink smooth to="#about" className="mark__top"></HashLink>
        </div>
        <h2 className="intro__title">
          Добро пожаловать на сайт СНТ &#171;РАДАР&#187;
        </h2>
        <div className="main__wrapper">
          <img
            className="main__img"
            src="СХЕМА.jpg"
            alt="схема садового общества"
          />
        </div>
      </section>
      <section className="main">
        <div className="main__title">
          <h1 style={{ textAlign: "center" }}>Важные объявления</h1>
        </div>
        <div className="main__wrapper">
          <div className="important">
            <ul className="important__list">
            <li className="important__item" style={{ textAlign: "center", backgroundColor:'red', borderRadius: '15px' }}>
              ВНИМАНИЕ ОСОБЫЙ ПРОТИВОПОЖАРНЫЙ РЕЖИМ !!!
            <div className="wrapper">
              <a
                href={doc3}
                target="_blank"
                rel="noreferrer"
                className="link_to_doc link_to_doc_red"
                >
                Посмотреть положение
              </a>
              <a
                href={doc4}
                target="_blank"
                rel="noreferrer"
                className="link_to_doc link_to_doc_red"
                >
                Посмотреть приказ
              </a>
            </div>
                </li>
              <li className="important__item">
                <p>
                  <p>
                    Уважаемые садоводы, срок оплаты взносов на 2025 год,
                    установлен не позднее 01.04.2025 г.!
                  </p>
                  <p>
                    В связи с проведением работ по установке шлагбаума в СНТ,
                    прошу начать производить оплату по реквизитам указанным в
                    разделе «Членские взносы». Для внесения в «базу» телефонных
                    номеров, с целью обеспечения проезда на территорию СНТ,
                    прошу выслать до 01.05.2025 г. номера телефонов с указанием
                    ФИО на номер Председателя в личных сообщениях watts app!
                  </p>
                  <p>
                    Обращаю внимание, что с 01 марта 2025 г. вступит в силу
                    Федеральный закон от 8 августа 2024 г. N 307-ФЗ "О внесении
                    изменений в Земельный кодекс Российской Федерации и статью
                    23 Федерального закона "О ведении гражданами садоводства и
                    огородничества для собственных нужд и о внесении изменений в
                    отдельные законодательные акты Российской Федерации",
                    которые регулируют порядок освоения и использования
                    земельных участков в границах населенных пунктов и участков,
                    предназначенных для ведения садоводства и огородничества.
                  </p>
                  <p>
                    По новому закону собственники земель в населенных пунктах,
                    владельцы садов и огородов будут обязаны подготовить участок
                    к использованию в течение трех лет с момента покупки.
                  </p>
                  <p>
                    Помимо установления срока, в законе появилось понятие
                    «освоение». Под ним понимается приведение участка в
                    пригодное для использования состояние в соответствии с его
                    назначением. Гражданину, который не привел в порядок участок
                    или сделал это несвоевременно, согласно КоАП, грозит штраф
                    20-50 тыс. руб.
                  </p>
                  <p>
                    Контрольно-надзорные органы могут зафиксировать признаки
                    неиспользования участка в том случае, если участок не был
                    подготовлен к использованию в течение трёх лет. При первом
                    нарушении выдаётся только предупреждение, никаких штрафов
                    при этом не налагается. Если собственник не исправил
                    нарушение в течение полугода, Росреестр в течение 30 дней
                    направит уведомление в уполномоченный орган, чтобы
                    инициировать дело в суд об изъятии участка. Согласно
                    документу, земельный участок, в том числе предназначенный
                    для индивидуального жилищного строительства (ИЖС), может
                    быть признан неиспользуемым в следующих случаях: - если в
                    течение двух лет и более на нём не был заложен фундамент или
                    возведены подземные конструктивные элементы; - если в
                    течение пяти лет после завершения строительства здание не
                    было зарегистрировано. Исключением являются ситуации, когда
                    разрешение на строительство предусматривает иные сроки.
                    Признаками неиспользуемых земель с существующими постройками
                    являются: - если здания не используются по своему прямому
                    назначению в течение пяти и более лет; - если конструктивные
                    элементы построек разрушены или повреждены (например,
                    разрушена крыша, повреждены стены, нет окон или стёкол), а
                    ремонт не проводился в течение пяти и более лет. Кроме того,
                    согласно документу, участки могут быть признаны
                    неиспользуемыми, если: - на земельном участке в течение трёх
                    и более лет не осуществляется деятельность, соответствующая
                    правовому режиму земельного участка; - участок захламлён
                    мусором, предметами, не связанными с использованием земли,
                    или зарос сорняками высотой более 1 метра и на более чем
                    50%. При этом собственник не проводит работы по очистке
                    участка в течение года и более. Захламление участка мусором
                    и сорняками, а также разрушение построек могут стать
                    причиной признания земельного участка, предназначенного для
                    частного приусадебного хозяйства, неиспользуемым. При этом
                    на таком участке в течение трёх лет и более не должны
                    выращиваться сельскохозяйственные культуры.
                  </p>
                  <p style={{ backgroundColor: "rgba(207, 227, 245, 0.5)" }}>
                    ПРИМЕР: Алексей купил два соседних участка в СНТ в апреле
                    2025 года. На первом расположена дача в хорошем состоянии: с
                    ухоженным садом и небольшим домиком. В этом случае земля уже
                    освоена и ею можно сразу пользоваться по назначению. Второй
                    участок был заброшен: он почти полностью зарос, а постройки
                    разрушились. Закон уже вступил в силу, поэтому до апреля
                    2028 года Алексей должен освоить землю, чтобы в дальнейшем
                    заниматься там садоводством: убрать сорняки, выкорчевать
                    погибшие деревья, снести обветшавшие строения, вывезти мусор
                    и так далее. Дом на этом участке он построить может, но не
                    обязан: земля находится в СНТ и вид её разрешённого
                    использования — для ведения садоводства, а вот проживание
                    целевым назначением не является.
                  </p>
                  <p>
                    Таким образом новый закон не ставит целью прекращение прав
                    граждан на землю. Он должен решить давнюю проблему
                    неосвоенных и заброшенных участков, которая в последние годы
                    неоднократно озвучивалась чиновниками разного уровня,
                    председателями садоводческих и огороднических товариществ.
                    Для участков, предназначенных для садоводства и
                    огородничества признаками заброшенности, будут захламление
                    или зарастание сорняками 50% площади и отсутствие
                    хозяйственной деятельности / неиспользование для выращивания
                    сельскохозяйственных растений в течение 3 и более лет.
                  </p>
                  <p style={{ fontWeight: "900" }}>
                    ПРЕДСЕДАТЕЛЬ СНТ НЕ НЕСЕТ ОТВЕТСТВЕННОСТЬ ЗА ИЗЪЯТИЕ У
                    ГРАЖДАН ЗЕМЕЛЬНЫХ УЧАСТКОВ, ОТНОШЕНИЯ ФОРМИРУЮТСЯ МЕЖДУ
                    СОБСТВЕННИКОМ И РОСРЕЕСТРОМ !!! А В СЛУЧАЯХ, ЕСЛИ
                    ПОТРЕБУЮТСЯ ПОКАЗАНИЯ СОСЕДИЙ ИЛИ ПРЕДСЕДАТЕЛЯ ДЛЯ
                    СОСТАВЛЕНИЯ ПРОТОКОЛА, ТО СЧИТАЮ ВСЕМ СТОИТ ЗАДУМАТЬСЯ ОБ
                    ОТНОШЕНИЯХ И ПОВЕДЕНИИ В НАШЕМ СНТ!!!
                  </p>
                </p>
              </li>
              <li className="important__item">
                <p>
                  Напоминаю о необходимости соблюдать правила пожарной
                  безопасности, правила внутреннего распорядка СНТ, не
                  заказывать большегрузную технику до апреля 2025 года, погасить
                  имеющиеся задолженности по оплате членских взносов,
                  восстановить разрушенный оголовник по ул. Лунинской (участки
                  60 и 76), восстановить дорогу по ул. Тенистой (110),
                  восстановить дорогу по ул. Дальневосточной (участок 24).
                  Председатель СНТ «РАДАР» Колотилин Дмитрий Владимирович.
                </p>
              </li>
              <li className="important__item">
                <p>
                  Уважаемые садоводы, по итогам проведенного 03.11.2024 г.
                  очередного собрания членов СНТ «РАДАР», информируем об
                  утверждении сметы на 2025 год, проведении противопожарного
                  инструктажа, обсуждении общих вопросов.
                </p>
                <p>
                  Срок оплаты взносов за 2025 год установлен не позднее
                  01.04.2025 г. Сумма 740,00 руб. за одну сотку членский взнос,
                  260,00 руб. за одну сотку целевой взнос. Всего за участок 6
                  соток сумма взносов 6 000,00 руб.
                </p>
                <p>
                  Протокол очередного общего собрания членов СНТ «РАДАР» от
                  03.11.2024 г.
                </p>
              </li>
              <li className="important__item">
                Уважаемые садоводы, по итогам проведенного 19.10.2024 г.
                собрания Правления СНТ «РАДАР», информируем о проведении
                очередного общего собрания членов СНТ «РАДАР» 03.11.2024 г.
                <a
                  href={doc2}
                  target="_blank"
                  rel="noreferrer"
                  className="link_to_doc"
                >
                  Открыть документ
                </a>
              </li>
              <li className="important__item">
                <p>
                  Уважаемые садоводы! С 01.07.2024 г. организована работа по
                  подключению частных домов и земельных участков в СНТ «РАДАР» к
                  сети интернет с использованием оптоволоконного кабеля.
                  Провайдер ТИС ДИАЛОГ, заявки возможно направлять по телефону:
                  +79062310821 — Ольга.
                </p>
              </li>
              <li className="important__item">
                <p style={{ color: "#ff0000", textAlign: "center" }}>
                  ДОГАЗИФИКАЦИЯ ЗАРЕГИСТРИРОВАННЫХ ЖИЛЫХ ДОМОВ В СНТ
                </p>
                <p style={{ color: "#ff0000", textAlign: "center" }}>
                  ВНИМАНИЕ!!! ЗАЯВКУ НА ДОГАЗИФИКАЦИЮ НЕОБХОДИМО ПОДАВАТЬ С
                  ПРИСВОЕННЫМ АДРЕСОМ ДОМОВЛАДЕНИЮ И ЗЕМЕЛЬНОМУ УЧАСТКУ,
                  ПОСТАНОВЛЕНИЕ О ПРИСВОЕНИИ АДРЕСОВ УЧАСТКАМ, РАЗМЕЩЕНО В
                  РАЗДЕЛЕ УЧРЕДИТЕЛЬНЫЕ ДОКУМЕНТЫ, ПРИСВОЕНИЕ АДРЕСА
                  ДОМОВЛАДЕНИЮ ДЕЛАЕТСЯ КАЖДЫМ САМОСТОЯТЕЛЬНО!!!
                </p>
              </li>
              <li className="important__item">
                <p style={{ lineHeight: "1.4em" }}>
                  По итогам проведенного Внеочередного общего собрания членов
                  СНТ «РАДАР» 22.06.2024 г. необходимая информация передана в
                  Администрацию Зеленоградского городского округа и
                  Правительство Калининградской области.
                </p>
                <p style={{ color: "blue" }}>
                  <NavLink to="/gaz"> Подробности по ссылке</NavLink>
                </p>
              </li>
              <li className="important__item">
                <p>
                  Ответ Правительства Калининградской области о ремонте
                  дорожного покрытия путепровода.
                  <a
                    href={doc}
                    target="_blank"
                    rel="noreferrer"
                    className="link_to_doc"
                  >
                    Открыть документ
                  </a>
                </p>
              </li>
              <li className="important__item">
                <p>
                  Внимание! Скоро введут значительные штрафы за не освоенные
                  земельные участки:
                  <a
                    style={{ marginLeft: "10px" }}
                    rel="noreferrer"
                    href="https://reader.rbc.ru/share/WiHorBhu4BxdePxT8"
                    target="_blank"
                  >
                    https://reader.rbc.ru
                  </a>
                  <p>
                    Прошу своевременно производить окос для избежания претензий
                    и изъятия.
                  </p>
                </p>
              </li>

              <li className="important__item">
                <p>
                  «Уважаемые садоводы, на общем собрании Правления СНТ «РАДАР»
                  07.04.2024 г. принято решение не проводить Общее собрание
                  членов СНТ «РАДАР» в весенний период 2024 г.
                </p>
              </li>
              <p className="important__item">
                Напоминаем о необходимости оплаты членских взносов за 2024 год
                до 01.05.2024 г.
              </p>
              <p className="important__item">
                Просим своевременно окашивать земельные участки и прилегающие
                дренажные канавы.
              </p>
              <p className="important__item">
                Заявки на установку фонарей направляются Председателю СНТ
                «РАДАР».
              </p>

              <li className="important__item" style={{ textAlign: "center" }}>
                ВНИМАНИЕ ПРОТИВОПОЖАРНЫЙ РЕЖИМ !!!
              </li>
              <div className="important__img">
                <img src="./приказ1.png" alt="ПРОТИВОПОЖАРНЫЙ РЕЖИМ" />
                <img src="./приказ2.png" alt="ПРОТИВОПОЖАРНЫЙ РЕЖИМ" />
              </div>
              <li className="important__item">
                Уважаемые садоводы! С 09.01.2024 г. организована работа по
                освещению СНТ &#171;РАДАР&#187; в рамках исполнения утвержденной
                сметы и работ на 2024 г.
              </li>
              <li className="important__item">
                Уважаемые садоводы! Убедительная просьба не заказывать доставку
                большегрузной техникой строительных и инертных материалов на
                участки в пределах СНТ &#171;РАДАР&#187; в период с ноября по
                апрель. Дороги разбиваются и не могут быть восстановлены за счет
                взносов СНТ в рамках утверждаемой сметы. В случае доставки
                строительных и инертных материалов в указанный период,
                обязанность по восстановлению дорожного покрытия будет возложена
                на собственника земельного участка.
              </li>
              <li className="important__item">
                Вечером в понедельник, 25 декабря 2023 года, случился крупный
                пожар в дачном обществе «ЦБЗ-1» в Калининграде. Подробности
                происшествия можно прочитать по ссылке:
                <a
                  href="https://ruwest.ru/news/137168/"
                  target="_blank"
                  rel="noreferrer"
                >
                  новости Калининграда
                </a>
                . Просьба соблюдать пожарную безопасность.
              </li>
              <li className="important__item">
                Ответ по дорожному покрытию моста от 28.11.2023г.
                <div
                  className={
                    isOpen ? "answer__wrapper" : "answer__wrapper_open"
                  }
                >
                  <button
                    className="answer__button"
                    onClick={() => handleClick()}
                  >
                    <img
                      src="answer.jpg"
                      alt="ответ по дорожному покрытию моста часть 1"
                      className={isOpen ? "smallImg" : "bigImg"}
                    />
                  </button>
                  <button
                    className="answer__button"
                    onClick={() => handleClick()}
                  >
                    <img
                      src="answer2.jpg"
                      alt="ответ по дорожному покрытию моста часть 2"
                      className={isOpen ? "smallImg" : "bigImg"}
                    />
                  </button>
                  <button
                    className="answer__button"
                    onClick={() => handleClick()}
                  >
                    <img
                      src="answer3.jpg"
                      alt="ответ по дорожному покрытию моста часть 3"
                      className={isOpen ? "smallImg" : "bigImg"}
                    />
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Main;
